<template>
  <div>
    <b-tabs content-class="mt-2" justified>
      <b-tab title="Consórcio">
        <b-card>
          <b-input-group class="mb-3">
            <b-input-group-prepend is-text size="sm">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Procurar" size="sm" v-model="search" debounce="800" type="search"/>
          </b-input-group>
          <div class="ranking-style overflow-auto">
            <div v-if="modules.ranking_bonificacao[1].length > 0">
              <b-table-simple striped responsive>
                <b-thead>
                  <b-tr class="text-center">
                    <b-th>Colaborador</b-th>
                    <b-th>Status</b-th>
                    <b-th>Valor de Vendas</b-th>
                    <b-th>Valor a Receber</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    :key="indextr"
                    v-for="(tr, indextr) in modules.ranking_bonificacao[1]"
                  >
                    <b-td class="text-center">
                      {{ tr.colaborador }}
                    </b-td>
                    <b-td class="text-center">
                      <feather-icon
                        icon="LockIcon"
                        size="18"
                        class="text-danger"
                        v-if="tr.status_consorcio == 0"
                      />
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                        class="text-success"
                        v-if="tr.status_consorcio == 1"
                      />
                    </b-td>
                    <b-td class="text-center">
                      {{ tr.valor_total | dinheiro}}
                    </b-td>
                    <b-td class="text-center">
                      {{ tr.valor_consorcio | dinheiro}}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div v-else>
              <b-card class="text-center">
                <h2>
                  Encontramos nenhum dado correspondente em nosso sistema!
                </h2>
                <p>
                  <small class="text-muted">Entre em contato com os administradores caso isso for um erro!</small>
                </p>
                <p class="mt-2">
                  <b-img
                    fluid
                    width="300"
                    :src="require('@/assets/images/pages/empty.svg')"
                  />
                </p>
              </b-card>
            </div>
          </div>
          <div v-if="modules.ranking_bonificacao[1].length > 0">
            <b-card>
              <h5>O Ranking de Bonificações poderá sofrer mudanças por conta deste itens:</h5>
              <b-list-group>
                <b-list-group-item class="d-flex">
                  <span class="mr-1">
                     <feather-icon
                      icon="AlertTriangleIcon"
                      size="16"
                    />
                  </span>
                  <span>1º Parcela Paga.</span>
                </b-list-group-item>

                <b-list-group-item class="d-flex">
                  <span class="mr-1">
                     <feather-icon
                      icon="AlertTriangleIcon"
                      size="17"
                    />
                  </span>
                  <span>Falta de Documentação.</span>
                </b-list-group-item>

                <b-list-group-item class="d-flex">
                  <span class="mr-1">
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="16"
                    />
                  </span>
                  <span>Inadimplência</span>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </b-card>
      </b-tab>
      <b-tab
        active
        title="Capital"
      >
        <b-card>
          <b-input-group class="mb-3">
            <b-input-group-prepend is-text size="sm">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Procurar" size="sm" v-model="search" debounce="800" type="search"/>
          </b-input-group>
          <div class="ranking-style overflow-auto">
            <div v-if="modules.ranking_bonificacao[0].length > 0">
              <b-table-simple striped responsive>
                <b-thead>
                  <b-tr class="text-center">
                    <b-th>Colaborador</b-th>
                    <b-th>Status</b-th>
                    <b-th>Valor de Vendas</b-th>
                    <b-th>Valor a Receber</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    :key="indextr"
                    v-for="(tr, indextr) in modules.ranking_bonificacao[0]"
                  >
                    <b-td class="text-center">
                      {{ tr.colaborador }}
                    </b-td>
                    <b-td class="text-center">
                      <feather-icon
                        icon="LockIcon"
                        size="18"
                        class="text-danger"
                        v-if="tr.status_capital == 0"
                      />
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                        class="text-success"
                        v-if="tr.status_capital == 1"
                      />
                    </b-td>
                    <b-td class="text-center">
                      {{ tr.valor_total | dinheiro}}
                    </b-td>
                    <b-td class="text-center">
                      {{ tr.valor_capital | dinheiro}}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div v-else>
              <b-card class="text-center">
                <h2>
                  Encontramos nenhum dado correspondente em nosso sistema!
                </h2>
                <p>
                  <small class="text-muted">Entre em contato com os administradores caso isso for um erro!</small>
                </p>
                <p class="mt-2">
                  <b-img
                    fluid
                    width="300"
                    :src="require('@/assets/images/pages/empty.svg')"
                  />
                </p>
              </b-card>
            </div>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
/* eslint-disable */    
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    mapActions,
    mapState
  },
  data() {
        return {
            search: null,
        }
    },
    computed: {
        ...mapState("connectAPI", ["modules"]),
    },
    methods: {
        fetchRanking(paginated = false) {
            this.isLoading = true;
            this.$store
                .dispatch("connectAPI/fetchRanking", {
                    pathAPI: "bonificacao",
                    paginated: paginated,
                    generic: this.search,
                })
                .then((response) => {
                })
                .catch((err) => {
                console.error(err);
                })
                .finally(() => {
                this.isLoading = false;
                });
        },
    },
    created(){
        this.fetchRanking();
    },
    watch: {
        search(){
            this.fetchRanking();
        }   
  },
}
</script>
<style lang="scss">
.ranking-gold {
  color: #ffd700;
}
.ranking-silver {
  color: #c0c0c0;
}
.ranking-bronze {
  color: #cd7f32;
}
.ranking-style {
  width: 100%;
  height: 475px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>
 